/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    a: "a",
    div: "div",
    p: "p",
    h2: "h2",
    ul: "ul",
    li: "li",
    strong: "strong",
    blockquote: "blockquote",
    h3: "h3"
  }, _provideComponents(), props.components), {LandingPageCta, MapInfo, SideBySide, ThreeColCta} = _components;
  if (!LandingPageCta) _missingMdxReference("LandingPageCta", true);
  if (!MapInfo) _missingMdxReference("MapInfo", true);
  if (!SideBySide) _missingMdxReference("SideBySide", true);
  if (!ThreeColCta) _missingMdxReference("ThreeColCta", true);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, {
    className: "c-md-h1",
    id: "invisible-hearing-aids-are-they-right-for-you",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#invisible-hearing-aids-are-they-right-for-you",
    "aria-label": "invisible hearing aids are they right for you permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Invisible hearing aids: are they right for you?"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Gone are the days of big and bulky ", React.createElement(_components.a, {
    href: "/hearing-aids/",
    className: "c-md-a"
  }, "hearing aids"), ". Over the past 50 years, these devices have advanced significantly—getting smaller, smarter, and far more discreet to the benefit of the one in six Americans experiencing hearing loss."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "For those with mild to moderate hearing loss, invisible hearing aids are an increasingly popular option, allowing wearers to hear more of the world around them with a powerful device hidden almost entirely inside the ear canal. Here, we’ll break down exactly what invisible hearing aids are, how they work, who they’re for, and how to weigh the pros and cons."), "\n", React.createElement(LandingPageCta, {
    copy: "Get Invisible Hearing Aids Now",
    classes: "c-cta--content"
  }), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "what-are-invisible-hearing-aids",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#what-are-invisible-hearing-aids",
    "aria-label": "what are invisible hearing aids permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "What are invisible hearing aids?"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Invisible hearing aids are the smallest, most discreet devices available to treat ", React.createElement(_components.a, {
    href: "/hearing-loss/",
    className: "c-md-a"
  }, "hearing loss"), ", typically used to treat mild to moderate cases. Over the past decade, engineers have managed to pack high-performance processors into smaller and smaller devices, which can now be customized to fit comfortably inside the ear canal, virtually invisible to others."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "They’re also sometimes referred to as ", React.createElement(_components.a, {
    href: "/hearing-aids/types/ite/",
    className: "c-md-a"
  }, "invisible-in-canal (IIC) hearing aids"), ", hidden hearing aids, or extended wear hearing aids—a modern alternative to ", React.createElement(_components.a, {
    href: "/hearing-aids/bte/",
    className: "c-md-a"
  }, "behind-the-ear (BTE)"), " and the slightly smaller in-the-canal (ITC) hearing aids."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Because they go by so many names, it can be useful to understand the difference between the many types and styles of hearing aids on the market these days:"), "\n", React.createElement(_components.ul, {
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, React.createElement(_components.strong, null, "Invisible-in-canal (IIC)"), " — another name for an invisible hearing aid, and the smallest option available. It sits fully inside your ear canal with the exception of a tiny pull tab which allows you to remove the device."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, React.createElement(_components.strong, null, "Completely-in-canal (CIC)"), " - still very discreet, these models also sit inside the ear canal, but the top can be seen to viewers looking from the side. Also best for those with mild to moderate hearing loss."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, React.createElement(_components.strong, null, "In-the-canal (ITC)"), " - slightly larger than CIC styles, these sit in the lower portion of the outer ear bowl."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, React.createElement(_components.strong, null, "In-the-ear (ITE)"), " - also referred to as “low profile hearing aids,” these devices are designed to fit directly into your ear, but fill a larger portion of it. They come in two primary sizes: “full shell,” which are designed to fit the entire area of your external ear, and “half shell,” which fills half of the outer bowl of your ear."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, React.createElement(_components.strong, null, "Behind-the-ear (BTE)"), " - these hearing aids are designed to sit neatly behind the ear, connected by a wire or plastic tube to a piece that sits inside the ear."), "\n"), "\n", React.createElement(_components.blockquote, null, "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "These devices come in two primary styles: open-fit—also known as receiver-in-the-ear (RITE) or receiver-in-canal (RIC)—where a tiny speaker sits invisibly inside the ear, attached to the hearing aid by a clear wire, or behind-the-ear with an earmold that fits inside the ear and is connected by a sound tube. BTE hearing aids are suitable for any kind of hearing loss, from mild to profound."), "\n"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Hearing aids are also sometimes referred to as ", React.createElement(_components.a, {
    href: "/hearing-aids/technology/bluetooth/",
    className: "c-md-a"
  }, "Bluetooth"), " or ", React.createElement(_components.a, {
    href: "/hearing-aids/technology/rechargeable/",
    className: "c-md-a"
  }, "rechargeable"), ", without specifying the type, so it’s important to check which of the styles listed above they’re referring to before making a final decision."), "\n", React.createElement(MapInfo), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "how-do-invisible-and-iic-hearing-aids-work",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#how-do-invisible-and-iic-hearing-aids-work",
    "aria-label": "how do invisible and iic hearing aids work permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "How do invisible and IIC hearing aids work?"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Invisible hearing aids are custom-made to fit the size and shape of the wearer’s ear canal, and programmed to meet their unique hearing loss needs. They work by capturing and analyzing surrounding sounds, and then amplifying what’s relevant based on the programming."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "First, a microphone collects sounds from the environment. Then a small-but-mighty computer processor adapts in real-time to your surroundings and adjusts the sound based on the programmed hearing loss pattern. The amplifier then raises the volume on certain frequencies, reduces background noise, and improves clarity."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "These new and improved signals are delivered to the ear via speaker, or receiver, filling in any gaps in listening the wearer might otherwise experience with a device that’s virtually invisible to others."), "\n", React.createElement(LandingPageCta, {
    copy: "Get Invisible Hearing Aids Now",
    classes: "c-cta--content"
  }), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "are-invisible-hearing-aids-right-for-you",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#are-invisible-hearing-aids-right-for-you",
    "aria-label": "are invisible hearing aids right for you permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Are invisible hearing aids right for you?"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "There are plenty of factors that go into choosing the right hearing aid, from the level of hearing loss to the shape of your ear canal, budget, and beyond. But here are a few of the pros and cons to think about if you’re considering an invisible hearing aid or IIC:"), "\n", React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "pros",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#pros",
    "aria-label": "pros permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "PROS:"), "\n", React.createElement(_components.ul, {
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, React.createElement(_components.strong, null, "Small & discreet"), " — invisible hearing aids are virtually impossible to see once they’re in place, with no external wires or tubes. With just a tiny pull tab to place and remove the device, these are perfect for anyone concerned about the cosmetic appearance."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, React.createElement(_components.strong, null, "Customized, comfortable fit"), " — most invisible hearing aids are custom-molded to fit the precise size and shape of your ear, so they’ll sit comfortably in place until it’s time to remove them."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, React.createElement(_components.strong, null, "More natural hearing experience"), " — because they’re situated all the way inside the ear canal, these devices are less likely to pick up environmental sounds like wind."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, React.createElement(_components.strong, null, "Bluetooth streaming & increasingly advanced tech"), " — the most modern invisible hearing aids include features like Bluetooth streaming and smartphone connectivity for more control and convenience."), "\n"), "\n", React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "cons",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#cons",
    "aria-label": "cons permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "CONS:"), "\n", React.createElement(_components.ul, {
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, React.createElement(_components.strong, null, "More delicate"), " — because of their placement inside the ear, invisible hearing aids are more susceptible to ear wax and moisture damage. Their smaller size means they can also be harder to handle for those with limited dexterity, making them more prone to potential loss or damage."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, React.createElement(_components.strong, null, "They don’t fit every ear"), "  — depending on the size and shape of your ear canal, these devices may not always fit properly or feel comfortable. Ear conditions like dermatitis, otitis externa or fungal infections may also limit your ability to wear them consistently."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, React.createElement(_components.strong, null, "Not suitable for severe hearing loss"), " — while these devices can significantly improve hearing for those with mild and moderate hearing loss, they’re limited slightly by their smaller size and power. For those with severe hearing loss, speech cannot always be picked up and amplified at the levels necessary."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, React.createElement(_components.strong, null, "Less manual control"), " — these days, wearers are more comfortable controlling their hearing aids with a smartphone or other Bluetooth device, but it’s important to note that invisible hearing aids are usually too small to accomodate manual control features like volume wheels or program buttons."), "\n"), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "what-are-the-best-invisible-hearing-aids",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#what-are-the-best-invisible-hearing-aids",
    "aria-label": "what are the best invisible hearing aids permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "What are the best invisible hearing aids?"), "\n", React.createElement(SideBySide, {
    imgSrc: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/trying-mini-ix.jpg"
  }, React.createElement(_components.p, {
    className: "c-md-p"
  }, "As with any medical decision, you’ll want to consult your doctor—more specifically, your audiologist—for advice on the style and features that make most sense for your particular situation and hearing loss pattern. But if you do decide to try an invisible hearing aid, we highly recommend hear.com’s impossibly ", React.createElement(_components.a, {
    href: "/hearing-aids/horizon/mini-ix/",
    className: "c-md-a"
  }, "small Horizon Mini IX"), ", which features the most advanced hearing aid technology platform to date. Here are some of the benefits:")), "\n", React.createElement(_components.ul, {
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, React.createElement(_components.strong, null, "Highly adaptive"), " — a high-performance processor that adjusts in real-time to changing environments and delivers brilliant sound in every situation"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, React.createElement(_components.strong, null, "Unprecedented speech clarity"), " — proprietary Speech Focus technology offers incredible clarity, filtering out background noise and making every word, syllable, and relevant sound easy to distinguish"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, React.createElement(_components.strong, null, "Smartphone app connectivity"), " — these tiny marvels make it possible to adjust settings and control your hearing aids conveniently using the hear.com's Horizon app on your phone"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, React.createElement(_components.strong, null, "Bluetooth streaming"), " — answer phone calls and stream TV shows, music, and audiobooks seamlessly, just like you might with an ear bud"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, React.createElement(_components.strong, null, "Relax mode"), " — this is the only invisible hearing aid that allows you to destress with soothing, ambient ocean sounds anytime you need a moment to yourself"), "\n"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Some other highly rated invisible hearing aids? Signia’s Insio IIC Nx, Starkey SoundLens Synergy IQ, and the Phonak Lyric."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "explore-invisible-hearing-aid-and-iic-options-today",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#explore-invisible-hearing-aid-and-iic-options-today",
    "aria-label": "explore invisible hearing aid and iic options today permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Explore invisible hearing aid and IIC options today"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Interested in trying one or more of these invisible hearing aids for yourself? If you or a loved one is experiencing hearing loss and want some general guidance about hearing aids, getting started with a hearing test, or trying out a pair on a risk-free basis, the experts at hear.com can help."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "They’re friendly, knowledgeable, and ready to answer your questions and connect you with some of the best audiologists in the country. And once you’ve got a prescription for a medical-grade hearing aid, you can test out some of the best invisible hearing aids on the market until you find what works for you, with a 45-day trial and a money-back guarantee."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "To get started, call us at (786) 520-2456 or tap the button and we’ll get in touch with you."), "\n", React.createElement(LandingPageCta, {
    copy: "Get Invisible Hearing Aids Now",
    classes: "c-cta--content"
  }), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "resources",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#resources",
    "aria-label": "resources permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Resources"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Learn everything you need to know about hearing aids and hearing loss."), "\n", React.createElement(ThreeColCta, {
    ImgSrc1: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/women-with-tablet.jpg",
    ContentTitle1: "Hearing Test",
    ContentCopy1: "Are hearing aids worth the cost? We asked an expert.",
    Url1: "/hearing-test/",
    ImgSrc2: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/hearing-aids-behind-ear-blur.jpg",
    ContentTitle2: "Value & Price",
    ContentCopy2: "Are hearing aids worth the cost? We asked an expert.",
    Url2: "/hearing-aids/prices/",
    ImgSrc3: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/go-ax-grey-blur.jpg",
    ContentTitle3: "Hearing Aids",
    ContentCopy3: "Connect you to the world around you with our wide range of devices.",
    Url3: "/resources/hearing-aids/"
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
